    <template>
    <div class="container my-4">
        <div v-if="alertMessage.description" class="rounded alert alert-dismissible alert-light">
            <div class="d-flex align-items-center">
                <i v-if="alertMessage.status === 'Success'" class="fas fa-check-circle text-success mr-2" />
                <i v-else class="fas fa-exclamation-triangle text-danger mr-2" />
                <span>{{ alertMessage.description }}</span>
            </div>
            <button @click="alertMessage = {description: '', status: ''}" class="ml-auto close" type="button">
                &times;
            </button>
        </div>
        <div class="card">
            <div class="card-header">Sales Sync</div>
            <div class="card-body">
                <Spinner v-if="loading" size="60" line-fg-color="gray" />
                <div v-else>
                    <div v-if="companies.length" class="d-flex flex-column">
                        <select v-model="selection"
                                :disabled="syncingWorksheet"
                                class="custom-select" style="margin-bottom: 1.5rem"
                                required>
                            <option :value="{company: {}, worksheet: {}}" disabled>
                                Select Company Sales Worksheet
                            </option>
                            <optgroup v-for="company in companies.filter(c => c['worksheets'].length)"
                                      :label="company.name"
                                      :key="company.name">
                                <option v-for="(worksheet, index) in company['worksheets']"
                                        :key="worksheet.id"
                                        :value="{company: company, worksheet: worksheet}">
                                    Sheet {{ index + 1 }}: {{ worksheet.title }}
                                </option>
                            </optgroup>
                        </select>
                        <button @click="syncWorksheet()"
                                :disabled="syncingWorksheet || !selection.worksheet.title"
                                class="btn btn-sm btn-primary ml-auto">
                            <span v-if="syncingWorksheet" class="spinner-border spinner-border-sm mr-2"/>
                            {{ syncingWorksheet ? 'Syncing' : 'Sync' }}
                        </button>
                    </div>
                    <div v-else>
                        No companies have been configured for syncing.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanies, getCompanySpreadsheet, updateCompanySpreadsheet } from '../../services/SalesSyncService';
import { hasPermissions } from '../../helpers/permissionUtils';
import Spinner from 'vue-simple-spinner';

export default {
    name: 'SalesSync',
    components: {
        Spinner
    },
    data() {
        return {
            loading: true,
            companies: [],
            alertMessage: {
                description: '',
                status: ''
            },
            syncingWorksheet: false,
            selection: {
                company: {},
                worksheet: {}
            }
        };
    },
    mounted() {
        this.getCompanySpreadsheets();
    },
    methods: {
        async getCompanySpreadsheets() {
            this.loading = true;
            try {
                const { data } = await getCompanies();
                this.companies = data;

                let companySpreadsheetRequests = [];
                this.companies.forEach(company => {
                    companySpreadsheetRequests.push(getCompanySpreadsheet(company.spreadsheet_key))
                })

                Promise.all(companySpreadsheetRequests).then(responses => {
                    responses.forEach(({data}) => {
                        let company_name, worksheets;
                        [company_name, worksheets] = Object.entries(data)[0];
                        let company = this.companies.find(company => company.name === company_name);
                        company.worksheets = worksheets;
                    })
                }).finally(() => {
                    this.loading = false;
                });
            } catch (error) {
                this.alertMessage = {
                    description: `Error - Failed to retrieve company data. Please refresh this page and try again.`,
                    status: 'Error'
                }
                this.loading = false;
            }
        },
        async syncWorksheet() {
            this.syncingWorksheet = true;
            this.alertMessage.status = '';
            try {
                const { data } = await updateCompanySpreadsheet(this.selection.company.spreadsheet_key, this.selection.worksheet.title);

                this.alertMessage = {
                    description: `Success - The worksheet ${data.worksheet_title} in ${data.spreadsheet_title} has been synced and is up to date!`,
                    status: 'Success'
                }
            } catch (error) {
                this.alertMessage = {
                    description: `Error - Your attempt to sync the worksheet was unsuccessful! Please refresh this page and try again.`,
                    status: 'Error'
                }
            }
            this.syncingWorksheet = false;
        },
        hasPermissions,
    },
};
</script>
