import axios from 'axios';

export const getCompanies = () => {
    return axios.get('tools/sales-sync/companies');
}

// The following functions are dependant on the return value(s) of getCompanies().

export const getCompanySpreadsheet = (spreadsheetKey) => {
    return axios.get(`tools/sales-sync/${spreadsheetKey}`);
}

export const updateCompanySpreadsheet = (spreadsheetKey, selectedWorksheetTitle) => {
    return axios.put(`tools/sales-sync/${spreadsheetKey}`, { selectedWorksheetTitle });
}
